<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <canvas-navigation-tabs :selectedTab.sync="selectedTabNavegationTabs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <canvas-data-table
          ref="canvasDataTable"
          :titulo="tituloTabela"
          :items="items"
          :loading="loading"
          @rechargeTable="chargeTableDecision(tipoListaCanvas)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CanvasService from '@/services/CanvasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    CanvasNavigationTabs: () =>
      import('@/components/canvas/CanvasNavigationTabs'),
    CanvasDataTable: () => import('@/components/canvas/CanvasDataTable')
  },

  data: () => ({
    items: [],
    tituloTabela: 'Mapas Personalizados',
    selectedTabNavegationTabs: 0,
    arrTour: [
      {
        element: '#tab-historico',
        intro:
          'Selecionando esta aba você poderá acessar todos os mapas personalizados cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#tab-favoritos',
        intro:
          'Selecionando esta opção você poderá acessar os mapas que você definir como seus favoritos.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#btn-novo-canvas',
        intro:
          'Clicando sobre este botão você poderá compor novos mapas selecionando layers personalizados.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#canvas-historico-search',
        intro: 'Neste campo você poderá pesquisar por mapas já cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-visualizar',
        intro:
          'Nesta opção você poderá visualizar o mapa com os layers selecionados.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-edit-rel',
        intro:
          'Clicando sobre este botão você poderá editar seu mapa, incluindo, alterando ou excluindo layers.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-favoritar-rel',
        intro:
          'Clicando sobre este botão você poderá marcar este mapa como favorito, podendo acessá-lo mais rapidamente através do menu superior "FAVORITOS".',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-rel',
        intro:
          'Aqui você acessa outras opções para seu mapa, como duplica-lo, deletá-lo e é possivel visualizar informações de criação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  created() {
    this.chargeTableDecision(this.tipoListaCanvas);
  },

  computed: {
    tipoListaCanvas() {
      return this.$store.getters.getTipoListaCanvas;
    }
  },

  methods: {
    getCanvas() {
      this.items = [];
      this.loading = true;
      CanvasService.getCanvas()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    getCanvasFavoritados() {
      this.items = [];
      this.loading = true;
      CanvasService.getCanvasFavoritados()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    chargeTableDecision(newDecisionInfo) {
      if (this.$refs.canvasDataTable) {
        this.$refs.canvasDataTable.page = 1;
      }

      this.tituloTabela = newDecisionInfo.titulo;

      switch (newDecisionInfo.tipo) {
        case 'favoritos':
          this.selectedTabNavegationTabs = 1;
          this.cancelRefreshData();
          this.refreshData(this.getCanvasFavoritados);
          break;
        case 'historico':
        default:
          this.selectedTabNavegationTabs = 0;
          this.cancelRefreshData();
          this.refreshData(this.getCanvas);
          break;
      }
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 4);
      this.iniciarTour(arrTourFormatted);
    }
  },

  watch: {
    tipoListaCanvas(newValue) {
      this.chargeTableDecision(newValue);
    }
  }
};
</script>
